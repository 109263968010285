<template>
	<div class="page-500 d-flex align-items-center justify-content-center">
		<div class="page-500-container">
			<div class="icon mb-3">
				500
			</div>
			<h1 class="title mb-1">
				Internal server error
			</h1>
			<p class="typo-body-2 color-black-45 mb-0">
				It’s us, not you. We’re working towards to making something better.
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PageServerError',
};
</script>

<style lang="scss" scoped>
	.page-500 {
		position: absolute; // relative with .c-body
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		align-items: center;
		text-align: center;
	}

	.icon {
		display: inline-block;
		width: rem(80);
		height: rem(80);
		line-height: rem(80);
		background-color: $color-gray-100;
		text-align: center;
		font-size: rem(28);
		color: $color-gray-500;
		font-weight: 600;
		border-radius: 50%;
	}

	.title {
		font-size: rem(18);
		font-weight: 600;
		color: $color-gray-500;
	}
</style>